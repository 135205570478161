<template>
  <main>
    <h1>Список изменений</h1>

    <h2>v0.1 — в разработке</h2>
    <p>Новая версия площадки Helperia находится на этапе разработки.</p>
  </main>
</template>

<script>
export default {
  name: 'Changelog',
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
  }

  h1, h2 {
    font-family: @font-set-header;
  }
</style>
